/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Custom Styles */

body {
  background: #313131;
  font-family: Roboto, sans-serif;
}

a,
a:hover,
a:visited {
  color: inherit;
}

.dark-text {
  color: #000;
}

.light-text {
  color: #fff;
}

.mast-head {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  z-index: 1;
}

.main-content {
  margin-top: 124px;
}

.home {
  background: url(../img/bg-home-red.jpg) top;
  background-size: cover;
}

.home .main-content {
  margin-top: 0;
}

@media (max-width: 768px) {
  .home .main-content {
    margin-top: 165px;
  }
}

.home-carousel {
  position: relative;
  min-height: 760px;
  overflow: hidden;
  display: flex;
  background-image: url(../img/bg-footer.jpg);
  background-position: center center;
  background-size: cover;
}

.home-carousel .carousel-inner {
  display: flex;
  padding-top: 300px;
}

.home-carousel .carousel-item {
  text-align: center;
  color: #fff;
  align-items: center;
}

.home-carousel h2 {
  text-transform: uppercase;
  font-size: 62px;
  font-weight: 300;
}

.home-carousel h2 * {
  display: block;
}

.home-carousel h2 strong {
  font-weight: 600;
}

.home-carousel h3 {
  margin-top: 30px;
  font-size: 26px;
  font-weight: 300;
}

.home-carousel .btn {
  background: #fff;
  border-radius: 25px;
  font-size: 18px;
  color: #545454;
  margin-top: 30px;
  border: none;
  padding: 5px 30px;
  text-transform: uppercase;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}

.home-carousel .slider-img-01 {
  position: absolute;
  left: 100px;
  top: -75px;
  max-width: 250px;
}

.home-carousel .slider-img-02 {
  position: absolute;
  right: 130px;
  top: -35px;
  max-width: 250px;
}

.home-carousel .slider-img-03 {
  position: absolute;
  left: 100px;
  top: -45px;
  max-width: 250px;
}

.home-carousel .slider-img-04 {
  position: absolute;
  right: 130px;
  top: -55px;
  max-width: 250px;
}

@media (max-width: 768px) {
  .home-carousel {
    position: relative;
    min-height: 480px;
    overflow: inherit;
  }

  .home-carousel .carousel-inner {
    padding-top: 70px;
  }

  .home-carousel h2 {
    font-size: 32px;
    text-align: center;
  }

  .home-carousel h2 span {
    padding: 0 !important;
    line-height: 48px;
  }

  .home-carousel h3 {
    font-size: 26px;
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .home-carousel .slider-img-01 {
    top: -10px;
    left: 100px;
  }

  .home-carousel .slider-img-02 {
    top: 10px;
    right: 100px;
  }

  .home-carousel .slider-img-03 {
    top: 30px;
    left: 100px;
  }

  .home-carousel .slider-img-04 {
    top: 0;
    right: 100px;
  }
}

@media (max-width: 992px) {
  .home-carousel .slider-img-01 {
    display: none;
  }

  .home-carousel .slider-img-02 {
    display: none;
  }

  .home-carousel .slider-img-03 {
    display: none;
  }

  .home-carousel .slider-img-04 {
    display: none;
  }
}

.navbar {
  background: #fff;
  padding: 0;
}

.logo img {
  width: 175px;
}

.top-bar {
  background: #c3161c;
  color: #fff;
  font-family: 'Abel', sans-serif;
  padding: 15px;
}

.top-bar .top-bar-txt {
  display: block;
  text-align: center;
  background: #c3161c;
  font-size: 19px;
}

.nav-item {
  text-align: center;
  color: #c3161c;
  font-weight: 500;
}

.nav-link.active {
  color: #2b2a29;
}

.social-media-icons {
  padding: 25px;
  border-left: 1px solid #ddd;
  height: 80px;
}

@media (max-width: 992px) {
  .social-media-icons {
    padding: 15px 15px 0 15px;
    border-left: 1px solid #ddd;
    height: auto;
  }
}

.home-search {
  padding: 25px;
  border-left: 1px solid #ddd;
  height: 80px;
}

@media (max-width: 992px) {
  .home-search {
    padding: 15px;
    border-left: 1px solid #ddd;
    height: auto;
  }
}

.home-search i {
  margin-top: 5px;
}

.home-search input {
  width: 200px;
  border: 1px solid #ddd;
  font-size: 13px;
  height: 32px;
  padding: 4px 12px;
}

@media (max-width: 1200px) {
  .home-search input {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .home-search input {
    width: 80%;
  }
}

.hamburger-menu {
  padding: 25px;
  border-left: 1px solid #ddd;
  height: 80px;
}

.navbar-toggler {
  background-color: #000;
  border: none;
  margin-right: 15px;
}

.navbar-toggler-icon {
  color: #fff;
  font-size: 21px;
  margin-top: 10px;
}

.hamburger-menu i {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.featured-products {
  margin-top: -15px;
}

@media (max-width: 768px) {
  .featured-products {
    margin-top: 0;
  }
}

.boxed-title {
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #313131;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 30px;
}

.featured-products .boxed-title {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  z-index: 1;
  margin-top: -60px;
}

@media (max-width: 768px) {
  .featured-products .boxed-title {
    position: static;
    margin-top: 30px;
  }
}

.featured-products .product {
  margin-bottom: 20px;
}

.featured-products .product .product-info {
  position: relative;
}

.featured-products .product h3 {
  top: 20px;
  font-size: 21px;
  font-weight: 500;
}

.featured-products .product .btn {
  top: 50px;
  background: #fff;
  border: none;
  border-radius: 30px;
  font-size: 10px;
  color: #000;
  font-weight: 500;
  padding: 8px 16px;
}

.featured-products .product .plus-sign {
  bottom: 15px;
  background: #6c6b69;
  color: #fff;
  padding: 3px 6px;
}

.featured-products .product img {
  width: 100%;
}

#wap-floating-btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #3ec050;
  color: #fff;
  border-radius: 50px;
  padding: 12px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

#wap-floating-btn i {
  font-size: 42px;
}

.trusted-clients {
  margin-top: 45px;
  border-top: 5px solid #fff;
  padding-top: 45px;
  background: url(../img/bg-footer.jpg);
  padding-bottom: 45px;
  background-size: cover;
}

.trusted-clients h4 {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}

.trusted-clients h4 .big-txt {
  font-size: 32px;
  font-weight: 500;
}

.trusted-clients .brands {
  background: #fff;
  padding: 60px;
}

.trusted-clients .brands img {
  width: 100%;
}

.product-list-page {
  background: #efefef;
}

.products .page-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  padding: 30px 0;
  background: #313131;
}

@media (max-width: 768px) {
  .products .page-title {
    padding: 60px 0 30px 0;
  }
}

.product-list {
  margin-top: 30px;
}

.product-list .row {
  background: #fff;
  margin: 0;
  padding-bottom: 30px;
}

.product-list-page .product-list img {
  width: 100%;
}

.product-list-page .product-list .product {
  margin: 15px 0;
}

.product-list-page .product-list .product .product-name {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px 0;
}

.product-list-page .product-list .product a:hover {
  text-decoration: none;
}

.product-list-page .product-list .product .product-code {
  display: block;
  text-align: center;
  font-size: 12px;
}

.product-list-page .category-title {
  text-align: center;
  color: #fff;
  background: #c3161c;
  font-size: 18px;
  padding: 12px;
}

.category-sidebar {
  margin-top: 30px;
}

.category-sidebar .bg {
  background: #fff;
}

.category-sidebar h3 {
  border-left: 3px solid #c3161c;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 18px;
}

.category-sidebar a {
  display: block;
  padding: 12px 18px;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}

.category-sidebar a .dot {
  width: 8px;
  height: 8px;
  background: #c3161c;
  margin-top: 3px;
}

.category-sidebar a.active {
  background: #313131;
  color: #fff;
}

.category-sidebar a.active .dot {
  background: #fff !important;
}

.category-sidebar a:hover {
  text-decoration: none;
}

.product-image-container,
.product-details {
  margin-top: 30px;
  background: #fff;
}
/* 
.product-image-container img {
  width: 100%;
} */

.product-page,
.nosotros {
  background: #efefef;
}

.product-page .page-title .btn i {
  margin-right: 4px;
}

.product-page .product-image-container,
.product-page .product-details {
  padding: 15px;
}

.product-page .product-details header {
  padding: 5px 0 15px 0;
  border-bottom: 1px solid #ddd;
}

.product-page .product-details h2 {
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .product-page .product-details h2 .code {
    float: none !important;
    display: block;
    font-size: 18px;
    margin-top: 12px;
  }
}

.product-page .product-details .product-description {
  color: #808080;
  font-size: 14px;
  line-height: 21px;
  margin: 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.product-consultation {
  max-width: 400px;
  position: relative;
}

@media (max-width: 768px) {
  .product-consultation {
    width: 100%;
    max-width: inherit;
  }
}

.contact-consultation input,
.contact-consultation textarea {
  width: 100%;
  border: 1px solid #ddd;
  font-size: 13px;
  height: 32px;
  padding: 4px 12px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .contact-consultation input,
  .contact-consultation textarea {
    height: 48px;
    padding: 15px 10px;
    margin-bottom: 15px;
  }
}

.product-consultation .btn {
  color: #fff;
}

.product-consultation textarea {
  resize: none;
  height: 100px;
}

.product-consultation .add-marker {
  position: absolute;
  right: -235px;
  top: 0;
  font-size: 10px;
  background: #efefef !important;
  border: none;
  color: #212121 !important;
}

@media (max-width: 768px) {
  .product-consultation .add-marker {
    position: static;
    top: 0;
    margin-bottom: 15px;
  }
}

.product-consultation .boxed-title {
  font-size: 14px;
  background: #000;
  color: #fff;
  clear: both;
  float: left;
  padding: 12px 20px;
  font-weight: 400;
}

.product-consultation .more-articles {
  background: #c3161c;
  border-radius: 15px;
  font-size: 14px;
  border: none;
  color: #fff;
}

.product-consultation .more-articles:visited {
  background-color: #c3161c !important;
  border-color: #c3161c !important;
}

@media (max-width: 1200px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 20px;
  }
}

@media (max-width: 992px) {
  .nav-item {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .social-media-icons,
  .home-search {
    width: 100%;
    float: none !important;
    text-align: center;
  }

  .nav-item {
    font-size: 18px;
  }
}

.nosotros .container {
  background: #fff;
  margin-top: 30px;
}

.nosotros .boxed-title {
  background: #666;
  color: #fff;
  margin: 0 -15px 30px -15px;
}

@media (max-width: 768px) {
  .nosotros .boxed-title {
    margin: 0 0 15px 0;
  }
}

.nosotros .img-container img {
  width: 100%;
  max-width: 300px;
  height: auto;
  float: right;
}

@media (max-width: 992px) {
  .nosotros .img-container img {
    max-width: 100%;
    float: none;
    margin-bottom: 15px;
  }
}

.nosotros .nosotros-description {
  color: #808080;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 15px 0;
}

.site-footer {
  background-image: url(../img/footer-bg.jpg);
  color: #bdbdbd;
  padding: 30px 0;
  font-size: 14px;
  line-height: 18px;
}

@media (max-width: 768px) {
  .brands .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.site-footer .cont {
  padding: 15px 20px;
  border-left: 1px solid #4a4a4a;
  min-height: 120px;
}

@media (max-width: 768px) {
  .site-footer .cont {
    padding: 0;
    border: none;
    min-height: inherit;
    text-align: center;
  }
}

.site-footer .logo {
  width: 100%;
}

@media (max-width: 768px) {
  .site-footer .logo {
    max-width: 75%;
    margin: 0 auto 30px auto;
    display: block;
  }
}

.site-footer h4 {
  font-size: 16px;
  margin-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  .product-page
    .carousel-inner
    .active.col-md-4.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  /* Show 3rd slide on sm if col-sm-6*/
  .product-page
    .carousel-inner
    .active.col-sm-6.carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .product-page .carousel-item {
    margin-right: 0;
  }

  /* show 2 items */
  .product-page .carousel-inner .active + .carousel-item {
    display: block;
  }

  .product-page
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .product-page
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item {
    transition: none;
  }

  .product-page .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  /* left or forward direction */
  .product-page
    .active.carousel-item-left
    + .carousel-item-next.carousel-item-left,
  .product-page .carousel-item-next.carousel-item-left + .carousel-item,
  product-page
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* farthest right hidden item must be also positioned for animations */
  .product-page .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* right or prev direction */
  .product-page
    .active.carousel-item-right
    + .carousel-item-prev.carousel-item-right,
  .product-page .carousel-item-prev.carousel-item-right + .carousel-item,
  .product-page
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

/* MD */
@media (min-width: 768px) {
  /* show 3rd of 3 item slide */
  .product-page .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }

  .product-page
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item {
    transition: none;
  }

  .product-page .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  /* left or forward direction */
  .product-page
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* right or prev direction */
  .product-page
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  .product-page
    .carousel-inner
    .active
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    display: block;
  }

  .product-page
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    transition: none;
  }

  /* Show 5th slide on lg if col-lg-3 */
  .product-page
    .carousel-inner
    .active.col-lg-3.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }

  /* left or forward direction */
  .product-page
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  /* right or prev direction //t - previous slide direction last item animation fix */
  .product-page
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
