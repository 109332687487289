.my-whatsapp-box .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.my-whatsapp-box .col-3,
.my-whatsapp-box .col-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-8,
.col-9 {
  -webkit-box-flex: 0;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.my-whatsapp-box {
  bottom: 10px;
  position: fixed;
  right: 10px;
  z-index: 999;
}

.my-whatsapp-box .whatsapp-btns .imgicon {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
  color: #fff;
  font-size: 1.625rem;
}

.my-whatsapp-box .whatsapp-chat-box {
  background: url('../img/bg-chat.png') 0 0 no-repeat;
  background-size: 380px;
  border-radius: 6px;
  bottom: 10px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 10px;
  width: 280px;
  z-index: 9999999;
}

.my-whatsapp-box .whatsapp-chat-box .close-widget-top {
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  height: 22px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 14px;
  width: 22px;
  z-index: 99999;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header {
  background: #00bf54;
  border-radius: 6px 6px 0 0;
  color: #fff;
  overflow: hidden;
  padding: 10px 14px;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header figure {
  background: #fff;
  border-radius: 50%;
  float: left;
  height: 36px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 36px;
  margin: 0px;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header figure img {
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
  width: 100%;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .fa-whatsapp {
  font-size: 1.375rem;
  margin-top: 5px;
  display: none;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .empresa {
  color: #fff;
  font-size: 13px;
  line-height: 0.9375rem;
  margin-left: 40px;
  padding-left: 4px;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .empresa span {
  display: block;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .empresa .tt1 {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 24px;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .empresa .tt2 {
  font-weight: 700;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .phone {
  background: #1fa34c;
  border: 1px solid #1ebf59;
  border-radius: 50%;
  display: inline-block;
  height: 34px;
  position: relative;
  top: 2px;
  vertical-align: middle;
  width: 34px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .phone:hover {
  border: 1px solid #1edc5b;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .phone a {
  color: #fff;
  display: block;
  height: 30px;
  width: 30px;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .phone i {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .close-widget {
  display: inline-block;
  font-size: 1.125rem;
  margin-left: 8px;
  padding: 8px 6px 0;
  vertical-align: middle;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .close-widget a {
  color: #1d8844;
}

.my-whatsapp-box .whatsapp-chat-box .chat-header .close-widget a:hover {
  color: #27da7c;
}

.my-whatsapp-box .whatsapp-chat-box .body {
  font-size: 0.8125rem;
  padding: 15px;
}

.my-whatsapp-box .whatsapp-chat-box .body .tag-label {
  background: #d4eaf4;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px 0 hsla(35, 14%, 59%, 0.32), 0 0 1px 0 #a39f98;
  box-shadow: 0 1px 1px 0 hsla(35, 14%, 59%, 0.32), 0 0 1px 0 #a39f98;
  color: #686868;
  margin: 0 auto 15px;
  padding: 3px 6px;
  width: 34px;
  text-align: center;
}

.my-whatsapp-box .whatsapp-chat-box .body .welcome_text {
  background: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.09);
  line-height: 1rem;
  margin: 4px 0;
  font-size: 14px;
  padding: 10px 10px 8px;
  line-height: 20px;
}

.my-whatsapp-box .whatsapp-chat-box .body .welcome_text #msg-time {
  color: #999;
  display: block;
  font-size: 0.6875rem;
  text-align: right;
}

.my-whatsapp-box .whatsapp-chat-box .foot {
  background: hsla(0, 0%, 100%, 0.5);
  border-radius: 0 0 6px 6px;
  padding: 15px;
}

.my-whatsapp-box .whatsapp-chat-box .foot a {
  color: #fff;
  display: block;
  text-decoration: none;
}

.my-whatsapp-box .whatsapp-chat-box .foot .input-group-text {
  cursor: pointer;
}

.my-whatsapp-box .whatsapp-chat-box .foot .form-control {
  resize: none;
}

.my-whatsapp-box .whatsapp-chat-box .foot .send_msg {
  background: #1bb24e;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  color: #fff;
  height: 40px;
  line-height: 2.5rem;
  padding: 0 12px;
  position: relative;
  width: 100%;
}

.my-whatsapp-box .whatsapp-chat-box .foot .send_msg i.mdi-send {
  border-left: 1px solid #22a04c;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 38px;
}

.my-whatsapp-box .sonar-wrapper {
  margin: auto;
  padding: 12px 10px 10px;
  z-index: 0;
}

.my-whatsapp-box .sonar-wrapper .sonar-emitter {
  background-color: #1bb24e;
  border-radius: 9999px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  height: 48px;
  margin: auto;
  position: relative;
  width: 48px;
}

.my-whatsapp-box .sonar-wrapper .sonar-wave {
  background-color: #1bb24e;
  border-radius: 9999px;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.my-whatsapp-box .sonar-wave {
  -webkit-animation: sonarWave 1.5s linear infinite;
  animation: sonarWave 1.5s linear infinite;
}

@-webkit-keyframes sonarWave {
  0% {
    opacity: 0.6;
  }
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes sonarWave {
  0% {
    opacity: 0.6;
  }
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.my-whatsapp-box .form-control {
  display: block;
  width: 100%;
  padding: 0.531rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.my-whatsapp-box .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.my-whatsapp-box .input-group > .custom-select:not(:last-child),
.my-whatsapp-box .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.my-whatsapp-box .input-group > .custom-file,
.my-whatsapp-box .input-group > .custom-select,
.my-whatsapp-box .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.my-whatsapp-box .input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.my-whatsapp-box .input-group-append {
  margin-left: -1px;
}

.my-whatsapp-box .input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.531rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.125rem;
}

.my-whatsapp-box .input-group > .input-group-append > .btn,
.my-whatsapp-box .input-group > .input-group-append > .input-group-text,
.my-whatsapp-box
  .input-group
  > .input-group-prepend:first-child
  > .btn:not(:first-child),
.my-whatsapp-box
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.my-whatsapp-box .input-group > .input-group-prepend:not(:first-child) > .btn,
.my-whatsapp-box
  .input-group
  > .input-group-prepend:not(:first-child)
  > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.arrow_box {
  background: #63d53a;
  border: 1px solid #cdcdcd;
  position: relative;
}

.arrow_box:after,
.arrow_box:before {
  border: solid transparent;
  content: ' ';
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
}

.arrow_box:after {
  border-color: rgba(99, 213, 58, 0);
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}

.arrow_box:before {
  border-color: transparent;
  border-right-color: #cdcdcd;
  border-width: 11px;
  margin-top: -11px;
}

.mdi.mdi-send {
  background: url('../img/send_icon.png')
    no-repeat;
  width: 20px;
  height: 18px;
  background-size: 100%;
}
